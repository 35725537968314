import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Select } from "clutch/src/Select/Select.jsx";
import type { DeepReadonly } from "ts-essentials";

import { readState } from "@/__main__/app-state.mjs";
import router, { updateRoute } from "@/__main__/router.mjs";
import {
  DEFAULT_SORT_BY,
  LeaderboardSortParamMap,
  PLAYER_LEADERBOARD_PAGE_LIMIT,
  SearchParamsEnum,
} from "@/game-eft/constants/constants.mjs";
import type {
  LeaderboardsPlayer,
  Profile,
} from "@/game-eft/models/graphql.mjs";
import deepFixStrings from "@/game-eft/utils/deep-fix-str-null.mjs";
import Container from "@/shared/ContentContainer.jsx";
import DataTable from "@/shared/DataTable.jsx";
import { SORT_ASC } from "@/shared/InfiniteTable.jsx";
import { ShowMoreFooter } from "@/shared/Profile.jsx";
import { filterErrState } from "@/util/eval-state.mjs";
import { sanitizeNumber } from "@/util/helpers.mjs";
import { formatToPercent, getLocaleString } from "@/util/i18n-helper.mjs";
import { useQuery, useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

type LeaderboardSortParam = keyof typeof LeaderboardSortParamMap;

const RegExpIsNumber = /^(num|avg|sum)|rate$|rank|level/;

const cssSelectContainer = () => css`
  align-self: flex-end;
`;

const leaderboards = Object.entries(LeaderboardSortParamMap).reduce(
  (acc, [key, { label, disabled = false }]) => {
    if (disabled) return acc;
    acc.push({
      text: label,
      value: key as LeaderboardSortParam,
    });
    return acc;
  },
  [],
);

export default function LeaderboardPlayers() {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const { state: routeState } = useRoute();
  const [sortBy, setSortBy] = useQuery<LeaderboardSortParam>(
    SearchParamsEnum.Leaderboard,
    DEFAULT_SORT_BY,
  );
  const profiles = state.eft.profiles;
  const $playerStats: {
    [page: string]:
      | DeepReadonly<Array<LeaderboardsPlayer | LeaderboardsPlayerSanitized>>
      | Error;
  } = useMemo(
    () => state.eft.leaderboards.players?.[sortBy] ?? {},
    [sortBy, state.eft?.leaderboards?.players],
  );
  const isLoading = !$playerStats[1];
  const page = useMemo(
    () =>
      Math.max(
        (routeState.transient?.page as number) ?? 0,
        ...(Object.keys($playerStats).map((i) =>
          sanitizeNumber(Number(i)),
        ) as Array<number>),
        1, // Initializer if all are 0
      ),
    [$playerStats, routeState.transient?.page],
  );

  const playerStats = useMemo(
    () =>
      Object.values($playerStats)
        .filter(filterErrState)
        .flat()
        .map((i) => {
          const $profile: Profile | Error | undefined =
            profiles[(i as LeaderboardsPlayer).account_id];
          const profile = filterErrState<Profile>($profile);
          const username = profile?.username ?? "";
          const result = { ...i, username };
          deepFixStrings(result);
          for (const key in result) {
            if (!RegExpIsNumber.test(key)) continue;
            result[key] = sanitizeNumber(Number(result[key]));
          }
          return result as LeaderboardsPlayerSanitized;
        }),
    [$playerStats, profiles],
  );
  const rows = useMemo(() => {
    // 1 represents the 1st page of the player leaderboards
    if (isLoading) return;
    return playerStats.map((i) =>
      [
        { value: i.rank, display: i.rank },
        {
          value: i.username,
          display: <a href={`/eft/profile/${i.account_id}`}>{i.username}</a>,
        },
        {
          value: i.level,
          display: i.level,
        },
        { value: i.avg_damage_per_raid, display: i.avg_damage_per_raid },
        { value: i.avg_kills_per_raid, display: i.avg_kills_per_raid },
        { value: i.num_kills, display: i.num_kills },
        { value: i.num_raids, display: i.num_raids },
        {
          value: i.survival_rate,
          display: formatToPercent(undefined, i.survival_rate),
        },
      ].map((i) => ({
        ...i,
        display:
          typeof i.display === "number"
            ? getLocaleString(Math.round(i.display))
            : i.display,
      })),
    );
  }, [isLoading, playerStats]);
  return (
    <Container className="flex column gap-sp-4 justify-end">
      <Select<LeaderboardSortParam>
        selected={sortBy}
        options={leaderboards}
        onChange={(value) => setSortBy(value)}
        containerClassName={cssSelectContainer()}
      />
      <DataTable
        indexCol={false}
        sortCol={0}
        sortDir={SORT_ASC}
        sortable={false}
        cols={[
          {
            display: t("battles:discover.rank", "Rank"),
            align: "left",
          },
          {
            display: t("common:player", "Player"),
            align: "left",
            isStat: false,
            primary: true,
          },
          {
            display: t("tft:level", "Level"),
            align: "right",
          },
          {
            display: t("eft:damageRaid", "Dmg / Raid"),
            align: "right",
            isStat: true,
          },
          {
            display: t("eft:killsRaid", "Kills / Raid"),
            align: "right",
            isStat: true,
          },
          {
            display: t("common:stats.kills", "Kills"),
            align: "right",
            isStat: true,
          },
          {
            display: t("eft:stats.raids", "Raids"),
            align: "right",
            isStat: true,
          },
          {
            display: t("eft:stats.survivalRate", "Surviv. Rate"),
            align: "right",
            isStat: true,
          },
        ]}
        rows={rows}
      />
      {!isLoading && (
        <ShowMoreFooter
          hasMore={page < PLAYER_LEADERBOARD_PAGE_LIMIT}
          setShowMore={loadNextPage}
        />
      )}
    </Container>
  );
}

function loadNextPage() {
  const {
    route: { currentPath, searchParams, state },
  } = router;
  const routeStateNext = { ...state };
  routeStateNext.transient = Object.assign(
    routeStateNext.transient ? { ...routeStateNext.transient } : {},
    {
      page: routeStateNext.transient?.page ?? 1,
    },
  );
  (routeStateNext.transient as { page: number }).page += 1;
  updateRoute(currentPath, searchParams, routeStateNext);
}

type LeaderboardsPlayerSanitized = {
  season_id: string;
  game_mode: string;
  faction: string;
  account_id: string;
  username: string;
  ranking_metric: string;
  rank: number;
  page: string;
  num_raids: number;
  survival_rate: number;
  num_kills: number;
  avg_kills_per_raid: number;
  sum_loot: number;
  avg_loot_per_raid: number;
  avg_damage_per_raid: number;
  level: number;
  filters: string;
};
