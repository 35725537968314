import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { IS_APP } from "@/__main__/constants.mjs";
import LeaderboardPlayers from "@/game-eft/components/LeaderboardPlayers.jsx";
import Container from "@/shared/ContentContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";

const title: Translation = ["eft:meta.leaderboards.title", "EFT Leaderboard"];

export default function Leaderboards() {
  const { t } = useTranslation();
  return (
    <LeaderboardLayout>
      <PageHeader imageRound title={t(...title)} />
      <Container>
        <LeaderboardPlayers />
      </Container>
    </LeaderboardLayout>
  );
}

export function meta() {
  return {
    title,
    description: [
      "eft:meta.leaderboards.description",
      "Climb to the top of Escape from Tarkov’s elite with Blitz.gg’s Leaderboard. Track your progress, compare stats, and see where you stand among the best EFT players. Strategize, improve, and claim your spot at the pinnacle of survival.",
    ],
    subtitle: !IS_APP,
  };
}

const LeaderboardLayout = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--sp-4);
  width: 100%;
`;
